import { Modal } from 'antd';
import { MainSubStructure, MainSubStructureModel, ImgFormatter } from 'egenie-utils';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import type { PrintParcelData } from './types';
import type { Store } from './store';
import { nanoid } from 'nanoid';

export class NotScanGoodsInformationModalStore {
  constructor(private parent: Store) {
  }

  @observable.ref public printParcelData: PrintParcelData = {};

  @observable public visible = false;

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'pic',
          name: '图片',
          width: 50,
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          key: 'skuNo',
          name: 'SKU编码',
        },
        {
          key: 'color',
          name: '颜色',
          width: 80,
        },
        {
          key: 'size',
          name: '尺寸',
          width: 80,
        },
        {
          key: 'warehouseBinNo',
          name: '库位',
          width: 200,
        },
        {
          key: 'num',
          name: '数量',
          width: 80,
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: '_id',
      showCheckBox: false,
      showReset: false,
      showSelectedTotal: false,
      showPager: false,
    },
    api: { onQuery: () => Promise.reject() },
    hiddenSubTable: true,
  });

  @action public handleCancel = (): void => {
    this.visible = false;
    this.printParcelData = {};
    this.mainSubStructureModel.gridModel.resetAll();
  };

  @action public handleOpen = (printParcelData: PrintParcelData): void => {
    this.visible = true;
    this.printParcelData = printParcelData;
    this.mainSubStructureModel.gridModel.resetAll();
    this.mainSubStructureModel.gridModel.rows = (printParcelData?.skuList || []).map((item) => ({
      ...item,
      _id: nanoid(),
    }));
  };

  @observable public isPrint = false;

  @action public handleOk = (): void => {
    this.isPrint = true;
    this.parent.printParcel(this.printParcelData)
      .then(() => {
        this.parent.handleResetState();
        this.handleCancel();
      })
      .finally(() => this.isPrint = false);
  };
}

@observer
export class NotScanGoodsInformationModal extends React.Component<{ notScanGoodsInformationModalStore: NotScanGoodsInformationModalStore; }> {
  render() {
    const {
      notScanGoodsInformationModalStore: {
        handleCancel,
        visible,
        mainSubStructureModel,
        handleOk,
        isPrint,
        printParcelData,
      },
    } = this.props;
    return (
      <Modal
        centered
        confirmLoading={isPrint}
        okText="继续打印"
        onCancel={handleCancel}
        onOk={handleOk}
        title={`未扫包裹(共有${printParcelData?.skuList?.length || 0}件商品未扫描)`}
        visible={visible}
        width={900}
      >
        <div style={{ height: 600 }}>
          <MainSubStructure store={mainSubStructureModel}/>
        </div>
      </Modal>
    );
  }
}
