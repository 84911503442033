export interface ListByPickOrderIdInterfaces {
  packNum?: number;
  packageNum?: number;
  packedNum?: number;
  serialNo?: string;
  wmsPickingOrderId?: number;
  outStockInfos?: OutStockItem[];
  packInfos?: PackItem[];
}

export interface OutStockItem {
  color?: string;
  otherStockDetailId?: number;
  otherStockId?: number;
  pic?: string;
  remainOutNum?: number;
  size?: string;
  skuId?: number;
  skuNo?: string;
  thisTimeOutNum?: number;
  totalOutNum?: number;
  highLight?: boolean;
}

export interface PackItem {
  expressNumber?: string;
  number?: number;
  packTime?: string;
}

export interface ScanItem {
  skuId?: string;
  pickType?: number;
  wmsPickingOrderId?: string;
  wmsOtherStockOrderId?: string;
  wmsPickingOrderNo?: string;
  otherOrderPackInfo?: {
    printData?: {
      tempData?: any;
      userDataList?: any[];
    };
  };

}

export enum ENUM_PRINT_PARCEL_TYPE {
  allParcel = 1,
  printedParcel = 2
}

export interface PrintParcelData {
  printData?: {
    tempData?: any;
    userDataList?: any[];
  };
  skuList?: Array<{
    pic?: string;
    skuNo?: string;
    color?: string;
    size?: string;
    warehouseBinNo?: string;
    num?: number;
    _id?: string;
  }>;
}

export interface WaveItem {
  cartNo?: string;
  serialNo?: string;
  wmsPickingOrderId?: string;
}
