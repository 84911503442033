import { Button, Col, Input, Row, Space, Select } from 'antd';
import { egeniePcTheme } from 'egenie-common';
import { Permission } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './packAndDispatchLeft.less';
import type { Store } from './store';
import { ENUM_PRINT_PARCEL_TYPE } from './types';

@observer
export class PackAndDispatchLeft extends React.Component<{ store: Store; }> {
  render() {
    const {
      printerValue,
      onPrinterValueChange,
      inputRef,
      information,
      goodsCodeValue,
      onGoodsCodeValueChange,
      onPressEnter,
      printers,
      isPrintExpress,
      handlePrintExpress,
      isPrintParcel,
      handlePrintParcel,
      wmsPickingOrderIdData,
      wmsPickingOrderId,
      setWmsPickingOrderId,
    } = this.props.store;
    return (
      <div className={styles.packAndDispatchLeft}>
        <div className={styles.packAndDispatchTitle}>
          <span className={styles.title}>
            打包发货
          </span>
        </div>
        <div className={styles.statistical}>
          <Select
            allowClear
            onChange={setWmsPickingOrderId}
            optionFilterProp="label"
            options={wmsPickingOrderIdData}
            placeholder="请选择波次号"
            showSearch
            size="large"
            style={{
              width: '100%',
              marginBottom: egeniePcTheme.spacing['egenie-spacing-xs'],
            }}
            value={wmsPickingOrderId}
          />
          <Row>
            <Col span={12}>
              <div>
                <span className={styles.statisticalNum}>
                  包裹数
                </span>
                <div className={styles.number}>
                  <span className={styles.tag}/>
                  <span className={styles.num}>
                    {information.packageNum}
                  </span>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div>
                <span className={styles.statisticalNum}>
                  打包数量
                </span>
                <div className={styles.number}>
                  <span className={styles.tag}/>
                  <span className={styles.num}>
                    {information.packedNum}
                  </span>
                  /
                  <span className={`${styles.num} ${styles.fontSize}`}>
                    {information.packNum}
                  </span>
                </div>
              </div>
            </Col>

            <div/>
          </Row>
          <div className={styles.commodityCodes}>
            <span>
              请扫描商品条码
            </span>
            <Input
              onChange={onGoodsCodeValueChange}
              onPressEnter={onPressEnter}
              placeholder="请扫描唯一码\商品条码"
              ref={inputRef}
              value={goodsCodeValue}
            />
          </div>
          <div className={styles.selectPrint}>
            <Select
              allowClear
              className={styles.printer}
              onChange={onPrinterValueChange}
              options={printers}
              placeholder="请选择打印机"
              value={printerValue}
            />
          </div>
          <div>
            <Space className={styles.buttonGroup}>
              <Permission permissionId="1651_4032">
                <Button
                  loading={isPrintParcel}
                  onClick={() => handlePrintParcel(ENUM_PRINT_PARCEL_TYPE.allParcel)}
                  type="primary"
                >
                  打印全部包裹
                </Button>
              </Permission>
              <Permission permissionId="1651_4033">
                <Button
                  loading={isPrintParcel}
                  onClick={() => handlePrintParcel(ENUM_PRINT_PARCEL_TYPE.printedParcel)}
                  type="primary"
                >
                  打印已扫包裹
                </Button>
              </Permission>
              <Permission permissionId="1651_720">
                <Button
                  loading={isPrintExpress}
                  onClick={handlePrintExpress}
                  type="primary"
                >
                  打印快递单
                </Button>
              </Permission>
            </Space>
          </div>
        </div>
      </div>
    );
  }
}
