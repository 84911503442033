import React from 'react';
import styles from './index.less';
import { PackAndDispatchLeft } from './packAndDispatchLeft';
import { PackAndDispatchRight } from './packAndDispatchRight';
import { Store } from './store';
import { NotScanGoodsInformationModal } from './notScanGoodsInformationModal';

export default function() {
  const store = React.useMemo(() => new Store(), []);
  return (
    <div className={styles.page}>
      <div className={styles.body}>
        <PackAndDispatchLeft store={store}/>
        <PackAndDispatchRight store={store}/>
        <NotScanGoodsInformationModal notScanGoodsInformationModalStore={store.notScanGoodsInformationModalStore}/>
      </div>
    </div>
  );
}
