import { Image, Timeline } from 'antd';
import { getStaticResourceUrl } from 'egenie-common';
import { EgGrid } from 'egenie-utils';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './packAndDispatchRight.less';
import type { Store } from './store';

@observer
export class PackAndDispatchRight extends React.Component<{ store: Store; }> {
  render() {
    const {
      egGridModel,
      packInfos,
      numberProgress,
    } = this.props.store;
    const getEgGridRows = egGridModel?.rows?.length || 0;

    return (
      <div className={styles.packAndDispatchRight}>
        <div className={styles.header}>
          <span>
            搜索结果
          </span>
          {
            getEgGridRows ? (
              <span className={styles.numberProgress}>
                本次打包进度：
                <span className={styles.numberProgress}>
                  {numberProgress || 0}
                </span>
              </span>
            ) : null
          }
        </div>
        {
          !getEgGridRows ? (
            <div className={styles.blank}>
              <Image
                className={styles.scanCodeImage}
                onDragStart={(e) => e.preventDefault()}
                preview={false}
                src={getStaticResourceUrl('pc/ts/egenie-cloud-wms/others/scanCode.png')}
              />
              <span className={styles.promptCopywriter}>
                请扫描拣货车号或波次号
              </span>
            </div>
          ) : null
        }
        {
          getEgGridRows ? (
            <div className={styles.scanResults}>
              <div className={styles.egGrid}>
                <EgGrid store={egGridModel}/>
              </div>
              <div className={styles.orderRecord}>
                <span className={styles.record}>
                  <span/>
                  单号记录
                </span>
                <div style={{
                  flexGrow: 2,
                  overflowY: 'auto',
                  height: 0,
                  paddingTop: 4,
                }}
                >
                  <Timeline>
                    {packInfos && packInfos.length && packInfos.map((item) => {
                      return (
                        <Timeline.Item color="green">
                          <p className={styles.orderNo}>
                            物流单号：
                            {item.expressNumber}
                          </p>
                          <p>
                            商品数量：
                            {item.number}
                          </p>
                          <p>
                            打印时间：
                            {item.packTime}
                          </p>
                        </Timeline.Item>
                      );
                    })}
                  </Timeline>
                  {
                    !packInfos || !packInfos.length ? (
                      <div className={`${styles.noData} ${styles.promptCopywriter}`}>
                        暂无单号记录
                      </div>
                    ) : null
                  }
                </div>
              </div>
            </div>
          ) : null
        }
      </div>
    );
  }
}
