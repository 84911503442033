import type { InputRef } from 'antd';
import { message } from 'antd';
import { printHelper, handleWayBillRemotePrintUrl } from 'egenie-common';
import type { BaseData, ValueAndLabelData } from 'egenie-utils';
import { request, EgGridModel, ImgFormatter } from 'egenie-utils';
import { action, observable } from 'mobx';
import { nanoid } from 'nanoid';
import React from 'react';
import { playVoice, voiceData } from '../../utils';
import { ENUM_PRINT_PARCEL_TYPE } from './types';
import type { ScanItem, ListByPickOrderIdInterfaces, PackItem, PrintParcelData, WaveItem } from './types';
import { NotScanGoodsInformationModalStore } from './notScanGoodsInformationModal';
import _ from 'lodash';

export class Store {
  constructor() {
    printHelper.getPrinters()
      .then((printers) => {
        this.printers = printers.map((item) => ({
          value: item,
          label: item,
        }));
      });

    this.getWmsPickingOrderIdData();
  }

  public notScanGoodsInformationModalStore: NotScanGoodsInformationModalStore = new NotScanGoodsInformationModalStore(this);

  public inputRef = React.createRef<InputRef>();

  @observable public goodsCodeValue = '';

  @action public onGoodsCodeValueChange = (e) => {
    this.goodsCodeValue = e?.target?.value;
  };

  @observable public printerValue: string;

  @observable public printers: Array<{ value: string; label: string; }> = [];

  @action public onPrinterValueChange = (value: string): void => {
    this.printerValue = value;
  };

  @observable public numberProgress = 0;

  @observable public information = {
    packNum: 0, // 打包数量
    packageNum: 0, // 包裹数
    packedNum: 0, // 已打包数量
  };

  @observable public isPrintExpress = false;

  @action public handlePrintExpress = async() => {
    this.inputRef.current?.focus();
    if (!this.wmsPickingOrderId) {
      playVoice(voiceData['请输入商品条码']);
      message.error('请扫描唯一码、商品条码');
      return;
    }

    if (!this.printerValue) {
      playVoice(voiceData['请选择打印机']);
      message.error('请选择打印机');
      return;
    }

    this.isPrintExpress = true;
    try {
      const info = await request<BaseData<{ dataList: any[]; }>>({
        method: 'POST',
        url: '/api/cloud/wms/rest/packAndDispatch/printPackAndExpress',
        data: { wmsPickingOrderId: this.wmsPickingOrderId },
      });

      const printData = await handleWayBillRemotePrintUrl(info?.data?.dataList);

      printHelper.toggleToRookie();
      await printHelper.print({
        preview: false,
        contents: printData,
        printer: this.printerValue,
      });

      this.numberProgress = 0;
    } catch (e) {
      console.error(e);
    } finally {
      this.isPrintExpress = false;
    }
  };

  @observable public isPrintParcel = false;

  public printParcel = async(printParcelData: PrintParcelData): Promise<void> => {
    printHelper.toggleToLodop();
    await printHelper.print({
      preview: false,
      templateData: printParcelData?.printData?.tempData,
      contents: printParcelData?.printData?.userDataList,
      count: 1,
    });
  };

  @action public handleResetState = () => {
    this.numberProgress = 0;
    this.pickType = null;
    this.setWmsPickingOrderId('');
    this.setWmsOtherStockOrderId('');
  };

  @action public handlePrintParcel = async(printParcelType: ENUM_PRINT_PARCEL_TYPE) => {
    this.inputRef.current?.focus();

    if (!this.wmsPickingOrderId) {
      playVoice(voiceData['请输入商品条码']);
      message.error('请扫描唯一码、商品条码');
      return;
    }

    this.isPrintParcel = true;
    try {
      const info = await request<BaseData<PrintParcelData>>({
        url: '/api/cloud/wms/rest/packAndDispatch/print/parcel',
        method: 'post',
        data: {
          printParcelType,
          pickType: this.pickType,
          wmsPickingOrderId: this.wmsPickingOrderId,
        },
      });
      if (printParcelType === ENUM_PRINT_PARCEL_TYPE.allParcel) {
        await this.printParcel(info.data);
        this.handleResetState();
      } else if (printParcelType === ENUM_PRINT_PARCEL_TYPE.printedParcel) {
        if (info?.data?.skuList?.length) {
          this.notScanGoodsInformationModalStore.handleOpen(info.data);
        } else {
          await this.printParcel(info.data);
          this.handleResetState();
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.isPrintParcel = false;
    }
  };

  @action public onPressEnter = async() => {
    const scanValue = this.goodsCodeValue.trim();
    if (scanValue.length === 0) {
      playVoice(voiceData['请输入商品条码']);
      message.error('请扫描唯一码、商品条码');
      return;
    }
    this.goodsCodeValue = '';

    try {
      const req = await request<BaseData<ScanItem>>({
        method: 'POST',
        url: '/api/cloud/wms/rest/packAndDispatch/scan',
        data: {
          scanValue,
          wmsPickingOrderId: this.wmsPickingOrderId,
          wmsOtherStockOrderId: this.wmsOtherStockOrderId,
        },
      });

      const userDataList = req.data?.otherOrderPackInfo?.printData?.userDataList;
      const templateData = req.data?.otherOrderPackInfo?.printData?.tempData;
      if (userDataList && templateData) {
        printHelper.toggleToLodop();
        await printHelper.print({
          preview: false,
          templateData,
          contents: userDataList,
          count: 1,
        });
      }

      playVoice(voiceData['通过']);
      message.success('扫描成功');
      this.numberProgress += 1;
      this.setWmsPickingOrderId(req?.data?.wmsPickingOrderId);
      this.setWmsOtherStockOrderId(req?.data?.wmsOtherStockOrderId);
      this.pickType = req?.data?.pickType;

      await this.getListByPickOrderId(scanValue);
    } catch (e) {
      playVoice(voiceData['错误']);
      console.error(e);
    }
  };

  public egGridModel: EgGridModel = new EgGridModel({
    rows: [],
    primaryKeyField: 'id',
    showPager: false,
    columns: [
      {
        key: 'pic',
        name: '图片',
        width: 50,
        formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
      },
      {
        key: 'skuNo',
        name: 'sku编码',
      },
      {
        key: 'color',
        name: '颜色',
      },
      {
        key: 'size',
        name: '尺码',
      },
      {
        key: 'totalOutNum',
        name: '总数量',
      },
      {
        key: 'remainOutNum',
        name: '剩余出库量',
      },
      {
        key: 'thisTimeOutNum',
        name: '本次出库',
      },
    ],
  });

  @observable public packInfos: PackItem[] = [];

  @observable public wmsPickingOrderIdData: ValueAndLabelData = [];

  @action public getWmsPickingOrderIdData = () => {
    request<BaseData<WaveItem[]>>({ url: '/api/cloud/wms/rest/packAndDispatch/picked/wave/list' })
      .then((info) => this.wmsPickingOrderIdData = (info.data || []).map((item) => ({
        value: `${item.wmsPickingOrderId }`,
        label: item.serialNo,
        ...item,
      })));
  };

  private pickType: ScanItem['pickType'] = null;

  @observable public wmsPickingOrderId: string = undefined;

  @action public setWmsPickingOrderId = (wmsPickingOrderId: string) => {
    this.wmsPickingOrderId = _.toString(wmsPickingOrderId) || undefined;
  };

  @observable public wmsOtherStockOrderId: string = undefined;

  @action public setWmsOtherStockOrderId = (wmsOtherStockOrderId: string) => {
    this.wmsOtherStockOrderId = _.toString(wmsOtherStockOrderId) || undefined;
  };

  @action public getListByPickOrderId = async(uniqueCode: string) => {
    const req = await request<BaseData<ListByPickOrderIdInterfaces>>({
      method: 'POST',
      url: '/api/cloud/wms/rest/packAndDispatch/listByPickOrderId',
      data: {
        wmsPickingOrderId: this.wmsPickingOrderId,
        pickType: this.pickType,
        uniqueCode,
      },
    });
    const {
      outStockInfos,
      packNum,
      packageNum,
      packedNum,
      packInfos,
    } = req.data;
    this.information = {
      packNum,
      packageNum,
      packedNum,
    };
    this.packInfos = packInfos || [];
    this.egGridModel.rows = (outStockInfos || []).map((item) => ({
      ...item,
      id: nanoid(),
    }));

    // 颜色区分已扫
    const activeItemIndex = this.egGridModel.rows.findIndex((item) => item.highLight);
    if (activeItemIndex !== -1) {
      this.egGridModel.onRowClick(activeItemIndex, this.egGridModel?.rows[activeItemIndex]);
    }
  };
}
